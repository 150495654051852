import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { rendTable, rendModal, api, saiki, submitForm, getCookie, numberFormat, loading } from '../modul';

function App() {
    document.getElementById("lblHeader").innerHTML = "Poin Reward";
    document.title = "Poin Reward";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameProduct",
                by: "DESC",
                datestart: saiki(),
                dateend: saiki(),
                idlocation: sessionStorage.getItem("location")
            };
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                const sort = this.state.by == "ASC" ? "DESC" : "ASC";
                this.setState({ by: sort });
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        async modalDelete(e) {
            const dom = (
                <>
                    <input type="hidden" name="iddata" value={e.target.dataset.id} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "points_product_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        async main() {
            let sql = await api("points_product_browse_web", {
                search: this.state.search,
                searchcount: this.state.searchcount,
                index: this.state.index,
                sort: this.state.sort,
                by: this.state.by,
                idadmin: getCookie("ID"),
                d1: this.state.datestart,
                d2: this.state.dateend,
                idlocation: this.state.idlocation,
            });

            rendTable({
                dataType: "url",
                dataset: sql,
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi: [
                        { type: "edit", event: (e) => rendForm(e) },
                        { type: "delete", event: (e) => this.modalDelete(e) }
                    ],
                    id: "tbodyData"
                }
            });
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={(e) => rendForm(e)}>Tambah Produk Poin</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination"></nav>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                __ID: getCookie("ID"),
                itemid: "",
                itemname: "",
                points: 0,
                isglobal: 0,
                ismultiple: 0,
                arrLokasi: [],
                isReadSearch: false,
                isReadPoints: true,
            }
        }

        async componentDidMount() {
            let arrLok = [];
            let sql = await api("location_browse", { index: 1, searchcount: 100, search: "" });
            for (let lok of sql.data) arrLok.push({ NameLocation: lok.NameLocation, ID: lok.ID, isLock: 0 });
            this.setState({ datalocation: arrLok });
            if (this.state.id !== "") {
                const sql = await api("points_product_detail", { iddata: this.state.id });
                this.setState({
                    itemid: sql.data[0].ItemID,
                    itemname: sql.data[0].NameProduct,
                    points: sql.data[0].Points,
                    isglobal: sql.data[0].isGlobal,
                    ismultiple: sql.data[0].isMultipleQty,
                    arrLokasi: sql.dataPointLocation,
                    isReadSearch: false,
                    isReadPoints: true,
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        handleChk(e, ssts) {
            this.setState({ [ssts]: e.target.checked == true ? 1 : 0 });
            if (ssts == "isglobal") {
                if (e.target.checked == true) {
                    this.setState({ arrLokasi: [] });
                }
            }
            if (this.state.isglobal == 0) {
                this.setState({ isReadSearch: true, isReadPoints: false, });
            } else {
                this.setState({ isReadSearch: false, isReadPoints: true, });
            }
        }

        modalCari(e) {
            const dom = (
                <>
                    <input type="search" className="form-control form-control-sm" onChange={(e) => this.cariProduct(e)} onFocus={(e) => this.cariProduct(e)} />
                    <p></p>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Nama Produk</th>
                                    <th>Harga</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id="tbodyCariProduk"></tbody>
                        </table>
                    </div>
                </>
            );

            rendModal({
                title: "Cari Produk",
                cls: "modal-lg",
                id: "modalCari",
                body: dom
            });
        }

        async cariProduct(e) {
            const sql = await api("product_browse", { search: e.target.value, index: 1, searchcount: 10 });
            const dom = sql.data.map(opt =>
                <tr style={{ cursor: "pointer" }} onClick={() => this.pilihProduct(opt.ID, opt.NameProduct)}>
                    <td>{opt.NameProduct}</td>
                    <td>{numberFormat(opt.Price)}</td>
                    <td></td>
                </tr>
            )
            ReactDOM.render(dom, document.getElementById('tbodyCariProduk'));
        }

        pilihProduct(id, name) {
            this.setState({ itemid: id, itemname: name });
            document.getElementById('btnCloseModalmodalCari').click();
        }

        async scLokasi(e) {
            this.setState({ namelocation: e.target.value });
            const sql = await api("location_browse", { search: e.target.value, searchcount: 100, index: 1 });
            const dom = sql.data.map((opt) =>
                <li><a className="dropdown-item" href="#" onClick={(e) => this.setLokasi(opt.NameLocation, opt.ID, 1)}>{opt.NameLocation}</a></li>
            )
            ReactDOM.render(dom, document.getElementById('ulLokasi'));
        }

        setLokasi(nama, id, poin) {
            let arrOld = this.state.arrLokasi;
            arrOld.push({ NameLocation: nama, IDLocation: id, Points: poin });
            this.setState({ arrLokasi: arrOld });
        }

        hapusData(i) {
            let arrOld = this.state.arrLokasi;
            let tmp = [];
            for(let ii in arrOld) if(ii != i) tmp.push(arrOld[ii]);
            this.setState({ arrLokasi: tmp });
        }

        handleChangeLokasi(e, i) {
            let arrOld = this.state.arrLokasi;
            arrOld[i].Points = e.target.value;
            this.setState({ arrLokasi: arrOld });
        }

        render() {
            return (
                <Fragment>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={this.state.__ID} />
                    <input type="hidden" name="isglobal" id="edtIsGlobal" value={this.state.isglobal} />
                    <input type="hidden" name="ismultipleqty" id="edtIsMultiple" value={this.state.ismultiple} />
                    <div className="form-group">
                        <label>Produk</label>
                        <input type="text" name="itemname" className="form-control" id="edtProductNama" value={this.state.itemname} onFocus={() => this.modalCari()} required />
                        <input type="hidden" name="itemid" id="edtProductID" value={this.state.itemid} />
                        <div className="invalid-feedback">Silahkan Pilih Produk</div>
                    </div>
                    <div className="form-group">
                        <label>Poin Global</label>
                        <input type="number" name="points" className="form-control" id="edtPoint" value={this.state.points} onChange={(e) => this.handleChange(e, "points")} readOnly={this.state.isReadPoints} />
                        <div className="invalid-feedback">Silahkan masukan poin</div>
                    </div>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="chkMuliple" onChange={(e) => this.handleChk(e, "ismultiple")} checked={this.state.ismultiple} />
                        <label className="form-check-label" for="chkMuliple">Multiple Qty</label>
                    </div>
                    <p></p>
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="chkGlobal" onChange={(e) => this.handleChk(e, "isglobal")} checked={this.state.isglobal} />
                        <label className="form-check-label" for="chkGlobal">Semua Cabang</label>
                    </div>
                    <div className="dropdown">
                        <input type="seacrh" id="edtCariCabang" className="form-control form-control-sm dropdown" placeholder="Cari Nama cabang" data-bs-toggle="dropdown" aria-expanded="false" onMouseOver={(e) => this.scLokasi(e)} onChange={(e) => this.scLokasi(e)} disabled={this.state.isReadSearch} />
                        <ul className="dropdown-menu w-100" aria-labelledby="edtCariCabang" id="ulLokasi"></ul>
                    </div>
                    <p></p>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Cabang</th>
                                <th>Poin</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody id="tbodyCabang">
                            {
                                this.state.arrLokasi.map((cabang, i) =>
                                    <tr>
                                        <td>
                                            <input type="text" value={cabang.NameLocation} className="form-control form-control-sm" disabled />
                                            <input type="hidden" value={cabang.IDLocation} className="tr-item" />
                                        </td>
                                        <td>
                                            <input type="number" value={cabang.Points} className="form-control form-control-sm tr-point" onChange={(e) => this.handleChangeLokasi(e, i)} required min="1" />
                                            <div className="invalid-feedback">Silahkan isi point</div>
                                        </td>
                                        <td><i className="material-icons icon-color" onClick={() => this.hapusData(i)}>delete</i></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    <textarea id="edtLokasi" name="datasetlocationpoints" style={{ display: "none" }} value={JSON.stringify(this.state.arrLokasi)}></textarea>
                </Fragment>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "points_product_edit" : "points_product_insert",
        title = id ? "Edit Promo" : "Tambah Promo";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form",
        cls: "modal-xl"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;