import React from 'react';
import { rendTable, rendModal, api, submitForm, saiki, numberFormat, loading, tanggalIndo, getCookie } from "../modul";
import ReactDOM from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "Customer Endorsement";
    document.title = "Customer Endorsement";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameCustomer",
                by: "ASC"
            };
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "endhorsement_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        main() {
            rendTable({
                url: "endorsement_browse_web",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi:
                        [{ type: "edit", event: (e) => rendForm(e) }, { type: "delete", event: (e) => this.modalDelete(e) }],
                    id: "tbodyData"
                }
            });
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Pelanggan</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                        </div>
                    </div>
                    <nav id="paggination"></nav>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                idcustomer: "",
                req: "required",
                namecustomer: "",
                datestart: saiki(),
                dateexpired: saiki(),
                desc: "",
                change: "no",
                datasetproduct: [],
                tableProduct: [],
                img: "https://pos.naylatools.com/assets/img/imgupload.png"
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("endorsement_detail", { iddata: this.state.id });
                console.log(sql);
                this.setState({
                    idcustomer: sql.data[0].IDCustomer,
                    namecustomer: sql.data[0].NameCustomer,
                    datestart: sql.data[0].DateStart,
                    dateexpired: sql.data[0].DateExpired1,
                    desc: sql.data[0].Description,
                    datasetproduct: JSON.stringify(sql.dataProduct),
                    img: `http://api.broadwaybarbershop.id:2005/file/${sql.data[0].Img}`
                });
                let arrProduct = [];
                for (let td of sql.dataProduct) arrProduct.push(td);
                this.setState({ tableProduct: arrProduct });
            }
            setTimeout(() => {
                document.getElementById('edtD1').value = tanggalIndo(this.state.datestart);
                document.getElementById('edtD2').value = tanggalIndo(this.state.dateexpired);
            }, 200);
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        openFile(e) {
            document.getElementById("labelImg").innerHTML = '';
            let files = e.target.files;
            let file;
            for (let i = 0; i < files.length; i++) {
                let reader = new FileReader();
                file = files[i];
                reader.onload = (file) => {
                    document.getElementById("labelImg").innerHTML += `<img src="${reader.result}" width="80px" height="80px" className="shadow-sm">`;
                    this.setState({ change: "yes" });
                }
                reader.readAsDataURL(file)
            }
        }

        hapusRow(e, id) {
            let arrData = this.state.tableProduct;
            for (let td in arrData) if (arrData[td].ID == id) arrData.splice(td, 1);
            this.setState({ tableProduct: arrData });
        }

        modalProduct() {
            const dom = (
                <>
                    <input type="search" class="form-control form-control-sm" onChange={(e) => this.cariProduct(e)} onMouseOver={(e) => this.cariProduct(e)} />
                    <p></p>
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th>Nama Produk</th>
                                    <th>Harga</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id="tbodyCariProduk"></tbody>
                        </table>
                    </div>
                </>
            );

            rendModal({
                title: "Cari Produk",
                cls: "modal-lg",
                id: "modalCari",
                body: dom
            });
        }

        async cariProduct(e) {
            const sql = await api("product_browse",
                {
                    search: e.target.value,
                    index: 1,
                    searchcount: 10
                }
            );
            const dom = sql.data.map(opt =>
                <tr style={{ cursor: "pointer" }} onClick={() => this.pilihProduct(opt)}>
                    <td> {opt.NameProduct}</td>
                    <td>{numberFormat(opt.Price)}</td>
                    <td></td>
                </tr>
            )
            ReactDOM.render(dom, document.getElementById('tbodyCariProduk'));
        }

        pilihProduct(opt) {
            let arr = this.state.tableProduct;
            arr.push(opt);
            this.setState({ tableProduct: arr });
            document.getElementById('btnCloseModalmodalCari').click();
        }

        modalCari() {
            const dom = (
                <>
                    <input type="search" className="form-control" placeholder="Cari Pelanggan" onKeyDown={(e) => this.cariPelanggan(e)} />
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Nama</th>
                                <th>Alamat</th>
                                <th>Telp</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody id="tbodyPelanggan"></tbody>
                    </table>
                </>
            );

            rendModal({
                title: "Cari Pelanggan",
                cls: "modal-lg",
                id: "modalCari",
                body: dom
            });
        }

        async cariPelanggan(e) {
            let sql = await api("customer_browse_web", { search: e.target.value, index: 1, searchcount: 100, sort: "NameCustomer", by: "asc" });
            let tr = [];
            sql.data.map(td => tr.push(
                <tr onClick={(e) => this.pilihPelanggan(e, td.NameCustomer, td.ID)}>
                    <td>{td.NameCustomer}</td>
                    <td>{td.Address}</td>
                    <td>{td.Telp}</td>
                    <td>{td.Email}</td>
                </tr>
            ));
            ReactDOM.render(tr, document.getElementById('tbodyPelanggan'));
        }

        pilihPelanggan(e, nama, id) {
            this.setState({ namecustomer: nama, idcustomer: id });
            document.getElementById('btnCloseModalmodalCari').click();
        }

        setType(e, on = "focus", ssts) {
            if (on == "focus") {
                e.target.value = this.state[ssts];
                e.target.type = 'date';
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state[ssts]);
            }
        }

        render() {
            return (
                <>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <input type="hidden" name="change" value={this.state.change} id="edtChange" />
                    <input type="hidden" name="datestart" value={this.state.datestart} />
                    <input type="hidden" name="dateexpired" value={this.state.dateexpired} />
                    <div className="form-group">
                        <label>Nama Pelanggan</label>
                        <input type="hidden" name="idcustomer" id="edtCustomerID" value={this.state.idcustomer} />
                        <input type="text" name="namecustomer" className="form-control" id="edtCustomer" value={this.state.namecustomer} onFocus={(e) => this.modalCari('cariPelanggan')} required />
                        <div className="invalid-feedback">Silahakan pilih pelanggan</div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Tanggal Mulai</label>
                                <input type="text" className="form-control form-date" id="edtD1" onChange={(e) => this.handleChange(e, "datestart")} onMouseOver={(e) => this.setType(e, 'focus', 'datestart')} onBlur={(e) => this.setType(e, "blur", 'datestart')} required />
                                <div className="invalid-feedback">Silahkan pilih tanggal mulai</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Tanggal Berahir</label>
                                <input type="text" className="form-control form-date" id="edtD2" onChange={(e) => this.handleChange(e, "dateexpired")} onMouseOver={(e) => this.setType(e, 'focus', 'dateexpired')} onBlur={(e) => this.setType(e, "blur", 'dateexpired')} required />
                                <div className="invalid-feedback">Silahkan pilih tanggal berahir</div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Deskripsi</label>
                        <textarea className="form-control" name="desc" value={this.state.desc} onChange={(e) => this.handleChange(e, "desc")} required></textarea>
                        <div className="invalid-feedback">Silahkan isi deskripsi</div>
                    </div>
                    <div className="form-group">
                        <label>Foto</label>
                        <div className="image-upload" >
                            <label for="file-input" id="labelImg" tooltip="Click Untuk Upload Gambar">
                                <img src={this.state.img} width="100px" className="shadow-sm" alt="gambar" />
                            </label>
                            <input id="file-input" name="Img" accept='image/*' onChange={(e) => this.openFile(e)} type="file" />
                            <div className="invalid-feedback">Silahkan pilih foto</div>
                        </div>
                    </div>
                    <textarea id="edtDataset" style={{ display: "none" }} name="datasetproduct" value={JSON.stringify(this.state.tableProduct)}></textarea>
                    <p></p>
                    <button type="button" className="btn btn-sm btn-default" onClick={() => this.modalProduct()}>Tambah Produk</button>
                    <p></p>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Nama Produk</th>
                                    <th>Harga</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id="tbodyProduct">
                                {
                                    this.state.tableProduct.map(tb =>
                                        <tr>
                                            <td>{tb.NameProduct}</td>
                                            <td>{numberFormat(tb.Price)}</td>
                                            <td><i className="material-icons icon-color" onClick={(e) => this.hapusRow(e, tb.ID)}>delete</i></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "endorsement_edit_web" : "endorsement_insert_web",
        title = id ? "Edit Pelanggan" : "Tambah Pelanggan";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;