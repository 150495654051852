import React from 'react';
import { rendTable, rendModal, api, submitForm, loading, getCookie } from "../modul";
import ReactDOM from 'react-dom';

function App() {
    document.getElementById("lblHeader").innerHTML = "Master Pelanggan";
    document.title = "Master Pelanggan";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameCustomer",
                by: "ASC"
            };
        }

        componentDidMount() {
            this.main();
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            this.main();
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        modalDelete(ini) {
            let dom = (
                <>
                    <input type="hidden" name="iddata" value={ini.target.dataset.id} />
                    <h5>Apakah Anda yakin akan menghapus data ini</h5>
                </>
            );
            rendModal({
                id: "modalDelete",
                body: dom,
                title: "Hapus Data",
                form: true,
                footer: `<button class="btn btn-danger" type="submit"><i class="material-icons">delete</i> Hapus</button>`
            });
            document.getElementById('iniForm').addEventListener("submit", function (e) {
                submitForm(e, { act: "customer_delete", reload: true, modal: "btnCloseModalmodalDelete", form: "#iniForm" });
            });
        }

        main() {
            rendTable({
                url: "customer_browse_web",
                param: { search: this.state.search, searchcount: this.state.searchcount, index: this.state.index, sort: this.state.sort, by: this.state.by },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: {
                    opsi:
                        [
                            { type: "edit", event: (e) => rendForm(e) },
                            { type: "delete", event: (e) => this.modalDelete(e) }
                        ],
                    id: "tbodyData"
                }
            });
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <button className="btn btn-sm btn-default" onClick={rendForm}>Tambah Pelanggan</button>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-3" style={{ textAlign: "right" }}>
                                <div className="input-group">
                                    <button className="btn btn-sc" onClick={this.main()}><i className="material-icons">search</i></button>
                                    <input type="search" value={this.search} onChange={(e) => this.handleChange(e, "search")} className="form-control form-control-sc form-control-sm" placeholder="Search" id="edtQ" />
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView">
                        </div>
                    </div>
                    <nav id="paggination"></nav>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

function rendForm(ini) {
    class Form extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                id: ini.target.dataset.id || "",
                name: "",
                desc: "",
                telp: "",
                address: "",
                email: ""
            }
        }

        async componentDidMount() {
            if (this.state.id !== "") {
                let sql = await api("customer_detail", { iddata: this.state.id });
                this.setState({
                    name: sql.data[0].NameCustomer,
                    desc: sql.data[0].Description,
                    telp: sql.data[0].Telp,
                    address: sql.data[0].Address,
                    email: sql.data[0].Email
                });
            }
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        render() {
            return (
                <>
                    <input type="hidden" name="iddata" value={this.state.id} />
                    <input type="hidden" name="idadmin" value={getCookie("ID")} />
                    <div className="row">
                        <div className="col-md-4 mb-2">
                            <div className="form-group">
                                <label>Nama</label>
                                <input type="text" name="name" className="form-control" id="edtName" value={this.state.name} onChange={(e) => this.handleChange(e, "name")} required />
                                <div className="invalid-feedback">Silahkan isi nama</div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-2">
                            <div className="form-group">
                                <label>Telp</label>
                                <input type="number" name="telp" className="form-control" id="edtTelp" value={this.state.telp} onChange={(e) => this.handleChange(e, "telp")} required />
                                <div className="invalid-feedback">Silahkan isi telp</div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-2">
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" name="email" className="form-control" id="edtEmail" value={this.state.email} onChange={(e) => this.handleChange(e, "email")} />
                                <div className="invalid-feedback">Silahkan isi email</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Alamat</label>
                                <textarea name="address" className="form-control" id="edtAlamat" onChange={(e) => this.handleChange(e, "address")} value={this.state.address} required></textarea>
                                <div className="invalid-feedback">Silahkan isi alamat admin</div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-group">
                                <label>Deskripsi</label>
                                <textarea name="desc" className="form-control" id="edtDesc" value={this.state.desc} onChange={(e) => this.handleChange(e, "desc")}></textarea>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    let id = ini.target.dataset.id,
        act = id !== undefined ? "customer_edit" : "customer_insert",
        title = id ? "Edit Pelanggan" : "Tambah Pelanggan";

    rendModal({
        title: title,
        form: true,
        body: <Form />,
        footer: "btnSave",
        id: "Form",
        cls: "modal-lg"
    });

    document.getElementById('iniForm').addEventListener("submit", function (e) {
        submitForm(e, { act: act, reload: true, modal: "btnCloseModalForm" });
    });
}

export default App;