import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { rendTable, saiki, loading, tanggalIndo, api, exportData, cekStatus, getCookie } from '../modul';

function App() {
    document.getElementById("lblHeader").innerHTML = "Transaksi Penukaran Poin";
    document.title = "Transaksi Penukaran Poin";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "ID",
                by: "DESC",
                datestart: saiki(),
                dateend: saiki(),
                idlocation: sessionStorage.getItem("location")
            };
        }

        main() {
            rendTable({
                url: "penukaran_poin_report_web",
                param: {
                    search: this.state.search,
                    searchcount: this.state.searchcount,
                    index: this.state.index,
                    sort: this.state.sort,
                    by: this.state.by,
                    idadmin: getCookie("ID"),
                    d1: this.state.datestart,
                    d2: this.state.dateend,
                    idlocation: this.state.idlocation
                },
                id: "iniTable",
                div: "divView",
                sort: (e) => this.handleSort(e),
                paggination: { event: (e) => this.handlePage(e), index: this.state.index },
                table: { cls: "table table-striped" },
                thead: { cls: "color-bg-biru" },
                tbody: { id: "tbodyData" }
            });
        }

        componentDidMount() {
            if (sessionStorage.getItem("datestart")) this.setState({ datestart: sessionStorage.getItem("datestart") });
            if (sessionStorage.getItem("dateend")) this.setState({ dateend: sessionStorage.getItem("dateend") });
            setTimeout(() => {
                document.getElementById('edtD1').value = tanggalIndo(this.state.datestart);
                document.getElementById('edtD2').value = tanggalIndo(this.state.dateend);
                this.main();
                loading();
            }, 200);
        }

        handleChange(e, ssts, fn = false) {
            this.setState({ [ssts]: e.target.value });
            sessionStorage.setItem([ssts], e.target.value);
            if (fn != false) {
                setTimeout(() => {
                    this.main();
                }, 200);
            }
        }

        handlePage(e) {
            e.preventDefault();
            this.setState({ index: e.target.dataset.val });
            setTimeout(() => {
                this.main();
            }, 100);
        }

        handleSort(e) {
            if (this.state.sort == e.target.dataset.sort) {
                if (this.state.by === "ASC") {
                    this.setState({ by: "DESC" });
                } else {
                    this.setState({ by: "ASC" });
                }
            } else {
                this.setState({ sort: e.target.dataset.sort });
            }
            setTimeout(() => {
                this.main();
            }, 100);
        }

        setType(e, on = "focus", ssts) {
            if (on == "focus") {
                e.target.value = this.state[ssts];
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state[ssts]);
            }
        }

        async handleDownload(e) {
            e.target.innerHTML = `<span className="material-icons">hourglass_bottom</span>`;
            e.target.disabled = true;
            let url = "penukaran_poin_report_web";
            let param = {
                search: this.state.search,
                searchcount: 99999,
                index: this.state.index,
                sort: this.state.sort,
                by: this.state.by,
                idadmin: getCookie("ID"),
                d1: this.state.datestart,
                d2: this.state.dateend,
                idlocation: this.state.idlocation
            }
            let sql = await api(url, param);
            exportData(sql, `Data Transaksi Penukaran Poin ${tanggalIndo(this.state.d1)} - ${tanggalIndo(this.state.d2)}`);
            setTimeout(() => {
                e.target.innerHTML = `<span className="material-icons">file_download</span>`;
                e.target.disabled = false;
            }, 2000);
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="d-flex justify-content-start align-items-center gap-2">
                            <h5>Periode</h5>
                            <div className="input-group input-group-sm gap-2">
                                <input type="text" name="edtD1" id="edtD1" style={{maxWidth:"120px"}} className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "datestart")} onMouseOver={(e) => this.setType(e, 'focus', 'datestart')} onBlur={(e) => this.setType(e, "blur", 'datestart')} />
                                <input type="text" name="edtD2" id="edtD2" style={{maxWidth:"120px"}} className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "dateend")} onMouseOver={(e) => this.setType(e, 'focus', 'dateend')} onBlur={(e) => this.setType(e, "blur", 'dateend')} />
                                <button className="btn btn-sm btn-default" onClick={(e) => this.main()}><i className="material-icons">search</i></button>
                                <button className="btn btn-sm btn-default" id="btnExport" onClick={(e) => this.handleDownload(e)}><span className="material-icons">file_download</span></button>
                            </div>
                        </div>
                        <p></p>
                        <div className="d-flex justify-content-start align-items-center gap-3">
                        </div>
                        <p></p>
                        <div className="table-responsive" id="divView"></div>
                    </div>
                    <nav id="paggination"></nav>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'))
}

export default App;