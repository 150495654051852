import React from 'react';
import "../Login.css";
import { setCookie, loading } from "../modul";

function App() {
    document.title = "Halaman Login";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                username: '',
                pwd: "",
            };
            this.handleSubmit = this.handleSubmit.bind(this);
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
        }

        componentDidMount() {
            loading();
        }

        handleSubmit(event) {
            event.preventDefault();
            let btn = document.getElementById("btnLogin");
            let form = document.getElementById('iniForm');
            let data = new FormData(form);
            // let host = "http://lawlawland.id:2005/api_mobile/";
            const host = "https://restapiaceh.broadwaybarbershop.id/api_mobile/";
            // let host = "http://localhost:2005/api_mobile/";
            fetch(host + "login_account_admin", {
                method: 'POST',
                body: data,
            }).then(response => response.json()).then(hasil => {
                if (hasil.status == "true") {
                    setCookie("ID", hasil.data.ID, 30);
                    setCookie("NM", hasil.data.NameAdmin, 30);
                    window.location.href = "./";
                } else {
                    alert(hasil.message);
                    btn.innerHTML = "Login";
                    btn.disabled = false;
                }
            }).catch((error) => {
                console.log("Error: " + error);
                btn.innerHTML = "Login";
                btn.disabled = false;
            });
        }

        render() {
            return (
                <>
                    <div className="header">
                        <div className="inner-header flex">
                            <h3>Broadway Login Page</h3>
                        </div>
                    </div>
                    <div className="content flex">
                        <form id="iniForm" onSubmit={this.handleSubmit}>
                            <input className="input" type="text" name="username" placeholder="Username" value={this.state.username} onChange={(e) => this.handleChange(e, "username")} required />
                            <input className="input" type="password" name="password" placeholder="Password" value={this.state.pwd} onChange={(e) => this.handleChange(e, "pwd")} required />
                            <button type="submit" className="login-btn" id="btnLogin">Login</button>
                        </form>
                    </div>
                </>
            )
        }
    }

    return (<Main />);
}

export default App;