import React from 'react';
import { api, getAllDay, saiki, numberFormat, loading, tanggalIndo, cekStatus } from "../modul";
import ReactDOM from 'react-dom';
import { Line } from 'react-chartjs-2';

function App() {
    document.getElementById("lblHeader").innerHTML = "Dashboard";
    document.title = "Dashboard";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameProduct",
                by: "DESC",
                mode: "hari",
                tgl: saiki(),
                location: window.sessionStorage.getItem("location"),
                bln: saiki().substring(0, 7),
                pay: [],
                laris: [],
                chart: {
                    labels: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
                    datasets: [
                        { "label": "Total Penjualan Per Jam", "data": [10, 20], "fill": false, "borderColor": "#00bcd4", "lineTension": 0.1 },
                        { "label": "Total Transaksi Per Jam", "data": [20, 12], "fill": false, "borderColor": "#c2185b", "lineTension": 0.1 }
                    ]
                },
                totalTrans: 0,
                TotalPenjualan: 0,
                Jasa: 0,
                Produk: 0,
                TotalTips: 0,
                Penerimaan: 0
            };
        }

        handleMode(mode) {
            this.setState({ mode: mode });
            setTimeout(() => {
                this.main();
                setTimeout(() => {
                    document.getElementById('edtD1').value = tanggalIndo(this.state.tgl);
                }, 200);
            }, 200);
        }

        componentDidMount() {
            if (window.sessionStorage.getItem("tgl")) this.setState({ tgl: window.sessionStorage.getItem("tgl") });
            if (window.sessionStorage.getItem("bln")) this.setState({ bln: window.sessionStorage.getItem("bln") });
            cekStatus();
            setTimeout(() => {
                this.main();
                document.getElementById('edtD1').value = tanggalIndo(this.state.tgl);
            }, 200);
            loading();
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            sessionStorage.setItem([ssts], e.target.value);
            setTimeout(() => {
                this.main();
            }, 200);
        }

        setType(e, on = "focus") {
            if (on == "focus") {
                e.target.value = this.state.tgl;
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state.tgl);
            }
        }

        async main() {
            if (this.state.mode == "hari") {
                document.getElementById('edtD1').style.display = "block";
                document.getElementById('edtMonth').style.display = "none";
                var total = [];
                var ct = [];
                var lbl = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
                var sql = await api("data_transaction_web", { idlocation: this.state.location, hh: lbl.toString(), mode: "hari", month: this.state.bln, date: this.state.tgl });
                for (let i of sql.data) {
                    total.push(i.Total);
                    ct.push(i.CT);
                }
                this.setState({
                    chart: {
                        labels: lbl,
                        datasets: [
                            { "label": "Total Penjualan Per Jam", "data": ct, "fill": false, "borderColor": "#00bcd4", "lineTension": 0.1 },
                            { "label": "Total Transaksi Per Jam", "data": total, "fill": false, "borderColor": "#c2185b", "lineTension": 0.1 }
                        ]
                    }
                })
                let payment = await api("dashboard_web", { mode: this.state.mode, date: this.state.tgl, month: this.state.bln, idlocation: this.state.location });

                if (payment.status == "true") {
                    let tdp = [];
                    for (let pay of payment.payment) tdp.push({ NamePayment: pay.NamePayment, TotalPayment: pay.TotalPayment });

                    let tdl = [];
                    for (let laris of payment.laris) tdl.push({ NameProduct: laris.NameProduct, Total: laris.Total });
                    this.setState({
                        pay: tdp,
                        laris: tdl,
                        totalTrans: payment.all.Semua,
                        TotalPenjualan: payment.all.CT,
                        Jasa: payment.jasa,
                        Produk: payment.produk,
                        TotalTips: payment.all.Total - payment.all.Semua,
                        Penerimaan: payment.all.Total
                    });
                }
            } else {
                document.getElementById('edtD1').style.display = "none";
                document.getElementById('edtMonth').style.display = "block";
                let total = [];
                let ct = [];
                let lbl = getAllDay(this.state.bln);
                let LBL = [];
                for (let hh of lbl) LBL.push(hh.substring(8));
                let sql = await api("data_transaction_web", {
                    idlocation: this.state.location,
                    hh: lbl.toString(),
                    mode: "bulan",
                    month: this.state.bln,
                });
                for (let i of sql.data) {
                    total.push(i.Total);
                    ct.push(i.CT);
                }
                this.setState({
                    chart: {
                        labels: lbl,
                        datasets: [{
                            "label": "Total Penjualan Per Jam",
                            "data": ct,
                            "fill": false,
                            "borderColor": "#00bcd4",
                            "lineTension": 0.1
                        }, {
                            "label": "Total Transaksi Per Jam",
                            "data": total,
                            "fill": false,
                            "borderColor": "#c2185b",
                            "lineTension": 0.1
                        }]
                    }
                })
                let payment = await api("dashboard_web", { mode: this.state.mode, date: this.state.tgl, month: this.state.bln, idlocation: this.state.location });

                let tdp = [];
                for (let pay of payment.payment) tdp.push({ NamePayment: pay.NamePayment, TotalPayment: pay.TotalPayment });

                let tdl = [];
                for (let laris of payment.laris) tdl.push({ NameProduct: laris.NameProduct, Total: laris.Total });
                this.setState({
                    pay: tdp,
                    laris: tdl,
                    totalTrans: payment.all.Semua,
                    TotalPenjualan: payment.all.CT,
                    Jasa: payment.jasa,
                    Produk: payment.produk,
                    TotalTips: payment.all.Total - payment.all.Semua,
                    Penerimaan: payment.all.Total
                });
            }
        }

        render() {
            return (
                <>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="input-group input-group-sm">
                                    <button className="btn btn-sm btn-primary">Periode</button>
                                    <input type="text" name="edtD1" id="edtD1" style={{ maxWidth: "150px" }} className="form-control form-control-sm form-date" value={this.state.tgl} onChange={(e) => this.handleChange(e, "tgl")} onMouseOver={(e) => this.setType(e)} onBlur={(e) => this.setType(e, "blur")} />
                                    <input type="month" name="edtMonth" id="edtMonth" style={{ maxWidth: "150px" }} className="form-control form-control-sm" value={this.state.bln} onChange={(e) => this.handleChange(e, "bln")} />
                                </div>
                            </div>
                            <div className="col-md-3 d-flex justify-content-end align-items-center">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-hari-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={(e) => this.handleMode('hari')}>HARI</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-bulan-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={(e) => this.handleMode('bulan')}>BULAN</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div id="divView">
                            <div className='div-card-sum'>
                                <div className="d-flex justify-content-start align-items-center gap-2">

                                    <div className='card card-sum'>
                                        <span style={{ fontSize: "14px;" }}>Total Transaksi</span>
                                        <span id="divCT" style={{ fontSize: "24px", fontWeight: "bold" }}>{numberFormat(this.state.totalTrans)}</span>
                                    </div>
                                    <div className='card card-sum'>
                                        <span style={{ fontSize: "14px;" }}>Total Penjualan</span>
                                        <span id="divCT" style={{ fontSize: "24px", fontWeight: "bold" }}>{numberFormat(this.state.TotalPenjualan)}</span>
                                    </div>
                                    <div className='card card-sum'>
                                        <span style={{ fontSize: "14px;" }}>Jasa</span>
                                        <span id="divCT" style={{ fontSize: "24px", fontWeight: "bold" }}>{numberFormat(this.state.Jasa)}</span>
                                    </div>
                                    <div className='card card-sum'>
                                        <span style={{ fontSize: "14px;" }}>Produk</span>
                                        <span id="divCT" style={{ fontSize: "24px", fontWeight: "bold" }}>{numberFormat(this.state.Produk)}</span>
                                    </div>
                                    <div className='card card-sum'>
                                        <span style={{ fontSize: "14px;" }}>Total Tips</span>
                                        <span id="divCT" style={{ fontSize: "24px", fontWeight: "bold" }}>{numberFormat(this.state.TotalTips)}</span>
                                    </div>
                                    <div className='card card-sum'>
                                        <span style={{ fontSize: "14px;" }}>Penerimaan</span>
                                        <span id="divCT" style={{ fontSize: "24px", fontWeight: "bold" }}>{numberFormat(this.state.Penerimaan)}</span>
                                    </div>
                                </div>
                            </div>
                            <p></p>
                            <div className="card shadow rounded" id="divChart" style={{ minHeight: "220px" }}>
                                <div style={{ maxHeight: "220px !important" }}>
                                    <Line
                                        height={"50px"}
                                        data={this.state.chart}
                                        options={{
                                            title: {
                                                display: true,
                                                text: 'Average Rainfall per month',
                                                fontSize: 20
                                            },
                                            legend: {
                                                display: true,
                                                position: 'right'
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <p></p>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card shadow rounded">
                                        <div className="card-header">
                                            <h5>Produk Terlaris</h5>
                                        </div>
                                        <div className="card-body" style={{ minHeight: "250px" }}>
                                            <table className="table table-striped" id="tbodyLaris">
                                                <tbody>
                                                    {this.state.laris.map(ll => <tr> <td>{ll.NameProduct}</td> <td align="right">{ll.Total}</td> </tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card shadow rounded">
                                        <div className="card-header">
                                            <h5>Promosi</h5>
                                        </div>
                                        <div className="card-body" style={{ minHeight: "250px" }}></div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="card shadow rounded">
                                        <div className="card-header">
                                            <h5>Metode Pembayaran</h5>
                                        </div>
                                        <div className="card-body" style={{ minHeight: "250px" }}>
                                            <table className="table table-striped" id="tbodyPayment">
                                                <tbody>
                                                    {this.state.pay.map(pay => <tr><td>{pay.NamePayment}</td><td align="right">{pay.TotalPayment}</td> </tr>)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

export default App;