import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import { Fragment } from 'react';
import { rendMenu, rendLokasi, changeLocation, rendModal, pesan, submitForm, getCookie, logout } from './modul.js';
import Customer from './page/Customer';
import Dashboard from './page/Dashboard';
import Booking from './page/Booking';
import Setoperator from './page/Setoperator';
import Counter from './page/Counter';
import Payment from './page/Payment';
import Location from './page/Location';
import Cost from './page/Cost';
import Hardware from './page/Hardware';
import Transaksipenjualan from './page/Transaksipenjualan';
import Penukaranpoin from './page/Penukaranpoin';
import Transaksiendors from './page/Transaksiendors';
import Customerendors from './page/Customerendors';
import Barberman from './page/Barberman';
import Logkasir from './page/Logkasir';
import Kategori from './page/Kategori';
import Kasir from './page/Kasir';
import Admin from './page/Admin';
import Promovoucher from './page/Promovoucher';
import Listvoucher from './page/Listvoucher';
import Product from './page/Product';
import Formulaproduct from './page/Formulaproduct';
import Promosi from './page/Promosi';
import Point from './page/Point';
import Penukaranpoint from './page/Penukaranpoint';
import Penjualanoutlete from './page/Penjualanoutlete';
import Pivotoperator from './page/Pivotoperator'
import Paymenttype from './page/Paymenttype';
import Penjualanproduk from './page/Penjualanproduk';
import Laporanpelanggan from './page/Laporanpelanggan';
import Aktifitascabang from './page/Aktifitascabang';
import TransaksiFranc from './page/TransaksiFranc';
import Login from './page/Login';
import PromoCustomer from './page/PromoCustomer';

function App() {
  setTimeout(async () => {
    let __ID = getCookie("ID") || "none";
    if (__ID != "none") {
      rendMenu();
      rendLokasi();
      window.addEventListener('popstate', function (event) {
        if (event.state != null) {
          let dataMenu = window.location.pathname.replace("/", "");
          pilihMenu(dataMenu, false);
        }
      });
      document.getElementById("sidebarCollapse").onclick = function () {
        document.getElementById("sidebar").style.display = "block";
        document.getElementById("sidebar").classList.toggle("active");
      }
    } else {
      ReactDOM.render(<Login />, document.getElementById('root'));
    }
  }, 500);

  return (
    <Fragment>
      <div style={{ position: 'fixed', bottom: '0px', height: '100%', width: '100%' }}>
        <div className="wrapper">
          <nav id="sidebar">
            <div className="sidebar-header" onClick={(e) => window.location.reload()} style={{ cursor: 'pointer' }}>
              <h3 className="logo-panjang"><img src="logo-panjang.png" alt="Broadway" width="100%" /></h3>
              <h3 className="logo"><img src="logo.png" alt="B" width="100%" /></h3>
            </div>
            <div className="transbox">
              <ul className="list-unstyled components" id="divMenu"></ul>
            </div>
          </nav>
          <div id="content">
            <div className="card" style={{ padding: '5px 10px 0 10px', backgroundColor: '#FEFEFE', borderRadius: '20px 20px 0 0' }}>
              <div className="row">
                <div className="col-md-9 d-flex justify-content-start align-items-center gap-4">
                  <nav className="navbar"> <button type="button" id="sidebarCollapse" className="btn btn-sm btn-default" style={{ borderRadius: '8px' }}> <i className="material-icons">menu</i> </button> </nav>
                  <span className="card-title"> <label style={{ fontSize: '18px', color: 'black' }} id="lblHeader"></label> </span>
                </div>
                <div className="col-md-3 d-flex justify-content-start align-items-center gap-4">
                  <div className="input-group input-group-sm">
                    <button className="btn btn-sm btn-secondary" style={{ backgroundColor: '#e9ecee !important', border: 'solid #e9ecee !important', borderRadius: '0' }} disabled><i className="material-icons" style={{ color: 'black' }}>room</i></button>
                    <select className="form-select" id="ddLocation"
                      onChange={(e) => {
                        e.preventDefault();
                        changeLocation(e);
                        pilihMenu(window.location.pathname.substring(1), false);
                      }}></select>
                    <div className="dropdown">
                      <button className="btn btn-sm" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="material-icons icon-color" style={{ fontSize: '30px', verticalAlign: 'bottom' }}>person</i> <label style={{ fontSize: ' 20px' }} id="lblNamaAdmin">Admin</label>
                      </button>
                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a className="dropdown-item" href="#" onClick={() => modalGantiPassword()}>Ganti Password</a> </li>
                        <li><a className="dropdown-item" href="#" onClick={() => logout()}>Log Out</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="tampil" className="card shadow shadow-lg" style={{ minHeight: '85vh', maxHeight: '85vh', borderRadius: '0 0 20px 20px', overflowY: 'scroll' }}></div>
          </div>
        </div>
      </div>
    </Fragment >
  );
}

function modalGantiPassword() {
  let dom = <Fragment>
    <input type="hidden" name="idadmin" value={getCookie("ID")} />
    <div className="form-group">
      <label>Password Lama</label>
      <input type="password" name="oldpassword" id="edtOldPassword" className="form-control" required />
      <div className="invalid-feedback">Silahkan isi Password Lama</div>
    </div>
    <div className="form-group">
      <label>Password Baru</label>
      <input type="password" name="newpassword" id="edtNewPassword1" className="form-control" required />
      <div className="invalid-feedback">Silahkan isi Password baru</div>
    </div>
    <div className="form-group">
      <label>Ulangi Password Baru</label>
      <input type="password" name="PWDN2" id="edtNewPassword2" className="form-control" required />
      <div className="invalid-feedback">Silahkan ulangi password baru</div>
    </div>
  </Fragment>

  rendModal({
    title: "Ganti Passworld",
    body: dom,
    form: true,
    footer: "btnSave"
  });

  document.getElementById('iniForm').addEventListener("submit", function (e) {
    e.preventDefault();
    let d1 = document.getElementById('edtNewPassword1').value;
    let d2 = document.getElementById('edtNewPassword2').value;
    if (d1 == d2 && d1 != "") {
      submitForm(e, { act: "profile_changepassword", reload: true, modal: "btnCloseModalForm" });
    } else {
      pesan("Info", "Password baru tidak sesuai", "warning");
    }
  });
}

export const pilihMenu = function (path, ch = true) {
  document.getElementById('loading').style.display = "block";
  var e = document.getElementsByClassName("active");
  for (let h = 0; h < e.length; h++) { e[h].classList.remove("active"); }
  if (ch == true) {
    window.history.pushState(path, "data", path);
    sessionStorage.removeItem('tgl');
    sessionStorage.removeItem('bln');
    sessionStorage.removeItem('d1');
    sessionStorage.removeItem('d2');
    sessionStorage.removeItem('datestart');
    sessionStorage.removeItem('dateend');
  }
  document.getElementById(path).className = "active";
  switch (path) {
    case 'frmcustomer':
      Customer();
      break;

    case 'frmdashboard':
      Dashboard();
      break;

    case 'frmboking':
      Booking();
      break;

    case 'frmsetoperatorloc':
      Setoperator();
      break;

    case 'frmcounter':
      Counter();
      break;

    case 'frmpayment':
      Payment();
      break;

    case 'frmlocation':
      Location();
      break;

    case 'frmcosttemplate':
      Cost();
      break;

    case 'frmhw':
      Hardware();
      break;

    case 'frmpromocus':
      PromoCustomer();
      break;

    case 'frmtransaction':
      Transaksipenjualan();
      break;

    case 'frmpenukaranpointreport':
      Penukaranpoin();
      break;

    case 'frmpenjualanendorsemen':
      Transaksiendors();
      break;

    case 'frmendorsement':
      Customerendors();
      break;

    case 'frmoperator':
      Barberman();
      break;

    case 'frmkasirlog':
      Logkasir();
      break

    case 'frmcategory':
      Kategori();
      break;

    case 'frmkasir':
      Kasir();
      break;

    case 'frmadmin':
      Admin();
      break;

    case 'frmvoucher':
      Promovoucher();
      break;

    case 'frmvouchertrans':
      Listvoucher();
      break;

    case 'frmproduct':
      Product();
      break;

    case 'frmformulaproduct':
      Formulaproduct();
      break;

    case 'frmpromo':
      Promosi();
      break;

    case 'frmpointproduct':
      Point();
      break;

    case 'frmpenukaranpoints':
      Penukaranpoint();
      break;

    case 'frmpenjualanoutlete':
      Penjualanoutlete();
      break;

    case 'frmpivotoperator':
      Pivotoperator();
      break;

    case 'frmpaymenttype':
      Paymenttype();
      break;

    case 'frmpivotproduct':
      Penjualanproduk();
      break;

    case 'frmcustomerreport':
      Laporanpelanggan();
      break;

    case 'frmbookinglist':
      Aktifitascabang();
      break;

    case 'frmtransactionfranc':
      TransaksiFranc();
      break;

    default: {
      let elm = <h1>Tidak ada menu</h1>
      document.getElementById("lblHeader").innerHTML = "Menu Tidak di temukan";
      document.title = "Broadway Barbershop";
      ReactDOM.render(elm, document.getElementById('tampil'))
    } break;
  }
  var x = window.matchMedia("(max-width: 768px)");
  if (x.matches == true) {
    document.getElementById("sidebar").classList.add("active");
  } else {
    document.getElementById("sidebar").classList.remove("active");
  }
}

export default App;
