import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { rendTable, tanggalIndo, api, exportData, saiki, numberFormat, loading, rendModal, TampilBulan, cekStatus } from "../modul";

function App() {
    document.getElementById("lblHeader").innerHTML = "Laporan Penjualan Produk";
    document.title = "Laporan Penjualan Produk";
    class Main extends React.Component {
        constructor (props) {
            super(props);
            this.state = {
                search: '',
                searchcount: 50,
                index: 1,
                sort: "NameProduct",
                by: "ASC",
                category: "0",
                mode: "hari",
                d1: saiki(),
                d2: saiki(),
                location: sessionStorage.getItem("location"),
                bln: saiki().substring(0, 7),
                arrCategory: [],
                total: 0,
                ct: 0,
                modeDetail: "hari",
                sortDetail: "DocDate",
                byDetail: "DESC",
                indexDetail: 1,
                id: 0
            };
        }

        async componentDidMount() {
            if (sessionStorage.getItem("d1")) this.setState({ d1: sessionStorage.getItem("d1") });
            if (sessionStorage.getItem("d2")) this.setState({ d2: sessionStorage.getItem("d2") });
            if (sessionStorage.getItem("bln")) this.setState({ bln: sessionStorage.getItem("bln") });
            let sqlCat = await api("category_browse_web", { search: "", index: 1, searchcount: 100, sort: "NameCategory", by: "ASC" });
            this.setState({ arrCategory: sqlCat.data });
            setTimeout(() => {
                this.main();
                cekStatus();
                document.getElementById('edtD1').value = tanggalIndo(this.state.d1);
                document.getElementById('edtD2').value = tanggalIndo(this.state.d2);
                loading();
            }, 200);
        }

        handleChange(e, ssts) {
            this.setState({ [ssts]: e.target.value });
            sessionStorage.setItem([ssts], e.target.value);
        }

        handleMode(mode) {
            this.setState({ mode: mode });
            setTimeout(() => {
                this.main();
            }, 200);
        }

        async rendDetail(e) {
            let d1 = this.state.d1;
            let d2 = this.state.d2;
            let month = this.state.bln;
            let id = e.target.dataset.id;
            this.setState({ id: id });
            let period = this.state.modeDetail == "hari" ? `${tanggalIndo(d1)} - ${tanggalIndo(d2)}` : TampilBulan(month);
            let dom = (
                <Fragment>
                    <h5 id="divName"></h5>
                    Periode : {period} <button class="btn btn-sm btn-default" id="btnExportDetail"><i class="material-icons">file_download</i></button>
                    <div class="table-responsive" id="detailTable"></div>
                </Fragment>
            );

            rendModal({
                title: "Detail Penjualan Produk",
                body: dom,
                cls: "modal-xl",
                id: "modalDetail"
            });
            this.mainDetail(period);
        }

        async mainDetail(period) {
            let d1 = this.state.d1;
            let d2 = this.state.d2;
            let month = this.state.bln;
            let sql = await api("detail_product_trans", {
                iddata: this.state.id,
                idlocation: this.state.location,
                mode: this.state.mode,
                datestart: d1,
                dateend: d2,
                month: month,
                sort: this.state.sortDetail,
                by: this.state.byDetail
            });

            document.getElementById('btnExportDetail').addEventListener("click", async function () {
                this.innerHTML = `<i class="material-icons">hourglass_bottom</i>`;
                this.disabled = true;
                exportData(sql, `Data Laporan Penjualan Produk ${sql.data[0].NameProduct} ${period}`);
                setTimeout(() => {
                    this.innerHTML = `<i class="material-icons">file_download</i>`;
                    this.disabled = false;
                }, 2000);
            });
            // document.getElementById('divName').innerHTML = sql.data[0].NameProduct;
            rendTable({
                dataset: sql,
                fn: "mainDetail",
                sorts: { sort: "sortDetail", by: "byDetail" },
                datatable: sql,
                div: "detailTable",
                table: { cls: "table table-striped" },
                thead: { cls: "thead-dark" },
                tbody: {
                    id: "tbodyDataDetail"
                }
            });
        }

        async main() {
            if (this.state.mode == "hari") {
                document.getElementById('edtD1').style.display = "block";
                document.getElementById('edtD2').style.display = "block";
                document.getElementById('edtMonth').style.display = "none";
            } else {
                document.getElementById('edtD1').style.display = "none";
                document.getElementById('edtD2').style.display = "none";
                document.getElementById('edtMonth').style.display = "block";
            }
            let param = {
                idlocation: this.state.location,
                d1: this.state.d1,
                d2: this.state.d2,
                month: this.state.bln,
                category: this.state.category,
                sort: this.state.sort,
                by: this.state.by,
                mode: this.state.mode
            };
            let sql = await api("pivot_product", param);
            let total = 0, ct = 0;
            for (let i of sql.data) {
                total += parseInt(i.Jumlah);
                ct += parseInt(i.Penjualan);
            }
            this.setState({ total: total, ct: ct });
            let thead = [
                { cap: "Produk", sort: "NameProduct", type: "link", link: (e) => this.rendDetail(e) },
                { cap: "SKU", sort: "CodeProduct" },
                { cap: "Kategori", sort: "NameCategory" },
                { cap: "Transaksi", sort: "Jumlah" },
                { cap: "Penjualan", sort: "Penjualan" }
            ];

            let tempData = { field: { thead: thead }, data: sql.data, };
            rendTable({
                dataset: tempData,
                div: "divGrid",
                table: { cls: "table table-striped" },
                thead: { cls: "thead-dark" },
                tbody: { id: "tbodyData" }
            });
        }

        async handleDownload(e) {
            e.target.innerHTML = `<i class="material-icons">hourglass_bottom</i>`;
            e.target.disabled = true;
            let param = {
                idlocation: this.state.location,
                d1: this.state.d1,
                d2: this.state.d2,
                month: this.state.bln,
                category: this.state.category,
                sort: this.state.sort,
                by: this.state.by,
                mode: this.state.mode
            };
            let sql = await api("pivot_product", param);
            let ct = 0, tot = 0;
            for (let td of sql.data) {
                ct += td.CT;
                tot += td.JML;
            }
            let thead = [
                { cap: "Produk", sort: "NameProduct", type: "link", link: (e) => this.rendDetail(e) },
                { cap: "SKU", sort: "CodeProduct" },
                { cap: "Kategori", sort: "NameCategory" },
                { cap: "Transaksi", sort: "Jumlah" },
                { cap: "Penjualan", sort: "Penjualan" }
            ];
            let tempData = { total: [" ", ct, tot], data: sql.data, field: thead };
            exportData(tempData, `Data Penjualan Produk ${tanggalIndo(this.state.d1)} - ${tanggalIndo(this.state.d2)}`, thead);
            setTimeout(() => {
                e.target.innerHTML = `<i class="material-icons">file_download</i>`;
                e.target.disabled = false;
            }, 2000);
        }

        setType(e, on = "focus", ssts) {
            if (on == "focus") {
                e.target.value = this.state[ssts];
                e.target.type = 'date';
                e.target.focus();
            } else {
                e.target.type = 'text';
                e.target.value = tanggalIndo(this.state[ssts]);
            }
        }

        render() {
            return (
                <Fragment>
                    <div className="card-header" id="divHeader"></div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-9">
                                <div className="d-flex justify-content-start align-items-center gap-2">
                                    <div className="input-group gap-2">
                                        <h5 id="hPeriod">Periode : </h5>
                                        <input type="text" id="edtD1" style={{ maxWidth: "120px" }} className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "d1")} onMouseOver={(e) => this.setType(e, 'focus', 'd1')} onBlur={(e) => this.setType(e, "blur", 'd1')} />
                                        <input type="text" id="edtD2" style={{ maxWidth: "120px" }} className="form-control form-control-sm form-date" onChange={(e) => this.handleChange(e, "d2")} onMouseOver={(e) => this.setType(e, 'focus', 'd2')} onBlur={(e) => this.setType(e, "blur", 'd2')} />
                                        <input type="month" id="edtMonth" style={{maxWidth:"150px"}} className="form-control form-control-sm" value={this.state.bln} onChange={(e) => this.handleChange(e, "bln")} />
                                        <button className="btn btn-sm btn-default" id="btnPreview" onClick={(e) => this.main(e)}><i className="material-icons">search</i></button>
                                        <button className="btn btn-sm btn-default" id="btnExport" onClick={(e) => this.handleDownload(e)}><i className="material-icons">file_download</i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="d-flex justify-content-end align-items-center">
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-hari-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={(e) => this.handleMode('hari')}>HARI</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-bulan-tab" data-bs-toggle="pill" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={(e) => this.handleMode('bulan')}>BULAN</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <p></p>
                        <div id="divView">
                            <div className="row">
                                <div className="col-md-4 mb-2">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6>Total Item</h6>
                                        </div>
                                        <div className="card-body" style={{ minHeight: "50px" }} id="divTotal">{numberFormat(this.state.ct)}</div>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-2">
                                    <div className="card">
                                        <div className="card-header">
                                            <h6>Total Penjualan</h6>
                                        </div>
                                        <div className="card-body" style={{ minHeight: "50px" }} id="divTotal2">{numberFormat(this.state.total)}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <select name="category" id="edtQ" onChange={(e) => this.handleChange(e, "category")} className="form-control form-control-sm">
                                            <option value="0">Semua Kategori</option>
                                            {this.state.arrCategory.map(opt => <option value={opt.ID}>{opt.NameCategory}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body table-responsive" id="divGrid"></div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    ReactDOM.render(<Main />, document.getElementById('tampil'));
}

export default App;